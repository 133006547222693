import React, { ReactElement } from "react";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { AppColors } from "../../../styles/colors";
import { Inner } from "../../shared/Inner";
import { Heading } from "../../shared/Heading";

import ReactLogo from "../../../assets/svg/react-logo.svg";
import TSLogo from "../../../assets/svg/ts-logo.svg";
import CSharpLogo from "../../../assets/svg/c-logo.svg";
import ManyMoreImg from "../../../assets/svg/many-more.svg";

interface Item {
  id: string;
  title: string;
  description?: string;
  icon?: ReactElement;
}

const items: Item[] = [
  {
    id: "react-native",
    title: "React Native",
    icon: ReactLogo,
  },
  {
    id: "typescript",
    title: "Typescript",
    icon: TSLogo,
  },
  {
    id: "c-sharp",
    title: "C#, .NET and more",
    icon: CSharpLogo,
  },
];

export const Technology: React.FC = () => {
  return (
    <Box>
      <Inner pt={{ xs: "0", md: "4rem" }} pb={{ xs: "4rem", md: "10rem" }}>
        <Heading
          title="Our Technology Stack"
          subtitle="As an IT firm, we understand the unique needs of our client projects and use the best tools to deliver optimised and maintainable solutions. To help you achieve your goals, we enhance our capacities by working with various programming languages, platforms, and new technologies. A list of our favourite languages is given below."
        />
        <Stack spacing={8}>
          <Box>
            <Grid container spacing={4} justifyContent="space-between">
              {items.map((item, i) => {
                const Icon = item.icon;
                return (
                  <Grid item md={4} key={item.id}>
                    <Box
                      p="1rem"
                      borderRadius="0.75rem"
                      border={(theme) => `1px solid ${theme.palette.card.main}`}
                      bgcolor={(theme) => theme.palette.card.light}
                    >
                      <Stack direction="row" spacing="1rem" alignItems="center">
                        <Box width="2.5rem" maxHeight="3rem" overflow="none">
                          <Icon style={{ maxWidth: "2.5rem" }} />
                        </Box>
                        <Typography
                          variant="h3"
                          sx={{
                            fontSize: "1.5rem",
                            color: AppColors.gray[700],
                          }}
                        >
                          {item.title}
                        </Typography>
                      </Stack>
                    </Box>
                  </Grid>
                );
              })}
              <Grid item md={12} mt="2rem">
                <Stack alignItems="center">
                  <ManyMoreImg />
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </Stack>
      </Inner>
    </Box>
  );
};
