import React from "react";
import { Box, Stack, styled } from "@mui/material";
import { Inner } from "../../shared/Inner";
import HeroImg from "../../../assets/hero-stock.jpg";
import { Heading } from "../../shared/Heading";

const Banner = styled(Box)`
  position: relative;
  width: 100%;

  div * {
    z-index: 1;
  }

  .bg {
    top: 0;
    right: -1rem;
    bottom: 0;
    left: -1rem;
    position: absolute;
    background: #f2f2f2;
    opacity: 0.8;
    z-index: 0;
  }
`;

const Container = styled(Inner)`
  display: flex;
  max-width: 100%;
  flex-direction: column;
  align-items: center;
  align-self: center;
  background: url(${HeroImg}) no-repeat 0%;
  background-size: cover;
`;

export const Hero: React.FC = () => {
  return (
    <Container>
      <Stack
        justifyContent="center"
        width="100%"
        minHeight={{ xs: "32rem", md: "48rem" }}
      >
        <Banner>
          <div className="bg" />
          <Inner>
            <Heading
              title="Quacking Good Software Development"
              subtitle="Our expert team will help take your app idea from concept to launch,
                  bringing unparalleled experience, reliability and 5* customer
                  service to every project."
            />
          </Inner>
        </Banner>
      </Stack>
    </Container>
  );
};
